import axios from "axios";
import * as bcrypt from "bcryptjs";
import { buildChannel } from "../util";
import { logEvent } from "../amplitude";
import { AuthenticationResponse, ListingType, NearestAirbnb } from "./types";

const BASE_URL_DEV = "http://127.0.0.1:8000";
const BASE_URL_CANARY = "https://pantheonai-canary-backend.co";
const BASE_URL_PROD = "https://pantheonai-backend.co";

const baseUrl = () => {
  const channel = buildChannel();
  switch (channel) {
    case "prod":
      return BASE_URL_PROD;
    case "canary":
      return BASE_URL_CANARY;
    case "dev":
      return BASE_URL_DEV;
  }
};

export const authenticate = async (
  password: string
): Promise<AuthenticationResponse> => {
  const endpointUrl = baseUrl() + "/authenticate";
  const hashedPassword = bcrypt.hashSync(
    password,
    "$2b$10$l2c6N6URnXKd5/pmaQZQtu"
  );

  return axios
    .post(endpointUrl, { hashed_password: hashedPassword })
    .then((response) => {
      if (response.status === 200) {
        console.log("Successfully signed in");
        return "success";
      }
      console.error("Authentication error:", response.status);
      return "failure";
    })
    .catch((error) => {
      // Handle error
      if (error.response && error.response.status === 401) {
        return "unauthorized";
      }

      console.error("Authentication error:", error);
      return "failure";
    });
};

export const generatePdfFromListingId = async (
  listingId: string,
  listingType: ListingType
): Promise<ArrayBuffer | undefined> => {
  const endpointUrl = baseUrl() + "/url/" + listingType + "/" + listingId;

  logEvent("start_fetch_listing", { listing_id: listingId });
  const startTime = Date.now();
  return axios
    .get(endpointUrl, {
      responseType: "arraybuffer",
    })
    .then((response) => {
      if (response.status === 200) {
        logEvent("finish_fetch_listing", {
          time_taken: Date.now() - startTime,
          listing_id: listingId,
        });
        return response.data;
      } else {
        throw new Error(
          "Fetch listing API request failed with status: " + response.status
        );
      }
    })
    .catch((error) => {
      console.error("Fetch listing API request failed:", error);
      return undefined;
    });
};

export const generatePdfFromPropertyUrls = async (
  urls: string[]
): Promise<ArrayBuffer | undefined> => {
  const endpointUrl = baseUrl() + "/generate_str_pdf_from_urls";

  logEvent("start_fetch_listing", { urls: urls });
  const startTime = Date.now();
  return axios
    .post(
      endpointUrl,
      { urls: urls },
      {
        responseType: "arraybuffer",
      }
    )
    .then((response) => {
      if (response.status === 200) {
        logEvent("finish_fetch_listing", {
          time_taken: Date.now() - startTime,
          urls: urls,
        });
        return response.data;
      } else {
        throw new Error(
          "Fetch listing API request failed with status: " + response.status
        );
      }
    })
    .catch((error) => {
      console.error("Fetch listing API request failed:", error);
      return undefined;
    });
};

export const getNearestAirbnb = async (
  address: string
): Promise<NearestAirbnb | undefined> => {
  // TODO: verify address is a reasonable format
  const endpointUrl = baseUrl() + "/nearest_airbnb/" + address;

  logEvent("start_fetch_nearest_airbnb");
  const startTime = Date.now();
  return axios
    .get(endpointUrl)
    .then((response) => {
      if (response.status === 200) {
        logEvent("finish_fetch_nearest_airbnb", {
          time_taken: Date.now() - startTime,
        });
        const { listing_id, distance_text } = response.data;
        return { listingId: listing_id, distanceText: distance_text };
      } else {
        throw new Error(
          "Nearest airbnb API request failed with status: " + response.status
        );
      }
    })
    .catch((error) => {
      console.error("Nearest airbnb API request failed:", error);
      return undefined;
    });
};
