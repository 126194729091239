import { InputText } from "primereact/inputtext";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { useRef, useState } from "react";
import styles from "./AirbnbVerification.module.css";
import { getNearestAirbnb } from "./api/routes";
import { Toast } from "primereact/toast";
import { NearestAirbnb } from "./api/types";

export const AirbnbVerification = () => {
  const [textValue, setTextValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [nearestAirbnb, setNearestAirbnb] = useState<NearestAirbnb | undefined>(
    undefined
  );
  const toast = useRef<Toast>(null);

  const fetchNearestAddress = async () => {
    // Convert to a reasonable address
    const address = textValue.trim().replaceAll(",", "-").replaceAll(" ", "-");
    setLoading(true);
    const nearestAirbnbInfo = await getNearestAirbnb(address);
    setLoading(false);
    if (nearestAirbnbInfo === undefined) {
      toast.current?.show({
        severity: "warn",
        summary: "Failed to Find Nearest Airbnb",
        detail:
          "Failed to locate the nearest Airbnb with unknown error. Please try again.",
        life: 5000,
      });
      return;
    }
    setNearestAirbnb(nearestAirbnbInfo);
  };

  return (
    <div className={styles.container}>
      <Card className={styles.card}>
        <Toast ref={toast} />
        <p className="m-0">Enter an address to find the nearest airbnb</p>
        <InputText
          value={textValue}
          placeholder="1313 Disneyland Dr, Anaheim, CA, 92802"
          onChange={(e) => setTextValue(e.target.value)}
          className={styles.inputText}
          onKeyDown={(event) => {
            if (event.key === "Enter" && textValue !== "") {
              fetchNearestAddress();
            }
          }}
        />
        <Button
          className={styles.submitButton}
          label="Find nearest Airbnb"
          disabled={textValue === ""}
          onClick={fetchNearestAddress}
          loading={loading}
        />
      </Card>
      {nearestAirbnb && (
        <Card className={styles.card}>
          <p className="m-0">{"Distance: " + nearestAirbnb.distanceText}</p>
          <a
            href={"https://www.airbnb.com/rooms/" + nearestAirbnb.listingId}
            target="_blank"
            rel="noreferrer"
          >
            Nearest Airbnb
          </a>
        </Card>
      )}
    </div>
  );
};
