import { InputText } from "primereact/inputtext";
import { ListingType, listingTypes } from "../../api/types";
import { Dropdown } from "primereact/dropdown";
import styles from "./Shared.module.css";

const displayNameForListingType = (listingType: ListingType) => {
  switch (listingType) {
    case "airbnb":
      return "Airbnb";
    case "vrbo":
      return "VRBO";
    case "evolve":
      return "Evolve";
    case "furnished_finder":
      return "Furnished Finder";
  }
};

type ListingIdFormProps = {
  textValue: string;
  setTextValue: (value: string) => void;
  selectedListingType: ListingType;
  setSelectedListingType: (value: ListingType) => void;
  generatePdf: () => void;
};

export const ListingIdForm: React.FC<ListingIdFormProps> = ({
  textValue,
  setTextValue,
  selectedListingType,
  setSelectedListingType,
  generatePdf,
}) => {
  return (
    <>
      <p>
        Enter a listing id and select your listing provider to generate a pdf.
      </p>
      <InputText
        value={textValue}
        placeholder="12345678"
        onChange={(e) => setTextValue(e.target.value)}
        className={styles.inputText}
        onKeyDown={(event) => {
          if (event.key === "Enter" && textValue !== "") {
            generatePdf();
          }
        }}
      />
      <Dropdown
        value={{
          name: selectedListingType,
          displayName: displayNameForListingType(selectedListingType),
        }}
        onChange={(e) => setSelectedListingType(e.value.name)}
        options={listingTypes.map((listingType) => {
          return {
            name: listingType,
            displayName: displayNameForListingType(listingType),
          };
        })}
        className={styles.dropdown}
        optionLabel="displayName"
        placeholder="Select a listing provider"
      />
    </>
  );
};
