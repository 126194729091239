import { useContext, useEffect, useState } from "react";
import { LoggedIn } from "../../LoggedIn";
import { LoggedOut } from "../../LoggedOut";
import { APP_VERSION } from "../../util";
import styles from "./Main.module.css";
import backgroundDark from "./backgroundDark.svg";
import backgroundLight from "./backgroundLight.svg";
import { ThemeContext } from "../../themes/Theme";

export const Main = () => {
  const [isAuthed, setIsAuthed] = useState(false);
  const [authAttemptCount, setAuthAttemptCount] = useState(0);

  const { theme } = useContext(ThemeContext);
  const isLightMode = theme === "light";
  const imgSrc = isLightMode ? backgroundLight : backgroundDark;

  useEffect(() => {
    // When the component mounts, check if there's a value stored in local storage
    const storedValue = localStorage.getItem("authedCompany");

    // If there's a stored value, update the state with it
    if (storedValue === "proper") {
      setIsAuthed(true);
    }
  }, [authAttemptCount]);

  return (
    <div className={styles.app}>
      <img src={imgSrc} className={styles.backgroundImg} />
      {isAuthed ? (
        <LoggedIn />
      ) : (
        <LoggedOut
          incrementAuthCount={() => setAuthAttemptCount(authAttemptCount + 1)}
        />
      )}
      <span className={styles.versionText}>{"version " + APP_VERSION}</span>
    </div>
  );
};
