export const APP_VERSION = process.env.REACT_APP_VERSION;
const BUILD_CHANNEL = process.env.REACT_APP_BUILD_CHANNEL;

export const isRunningLocally = () => {
  return process.env.NODE_ENV !== "production";
};

export const buildChannel = () => {
  if (BUILD_CHANNEL === "prod") {
    return "prod";
  }
  if (BUILD_CHANNEL === "canary") {
    return "canary";
  }
  return "dev";
};

export const sleepMs = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};
