import { useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import styles from "./UwAssistant.module.css";
import {
  generatePdfFromListingId,
  generatePdfFromPropertyUrls,
} from "../../api/routes";
import { Toast } from "primereact/toast";
import { ListingType, listingTypes } from "../../api/types";
import { SelectButton } from "primereact/selectbutton";
import { ListingIdForm } from "./ListingIdForm";
import { PropertyLinksForm } from "./PropertyLinksForm";

type ListingPdf = {
  listingId: string;
  pdfUrl: string;
  fromPropertyLinks: boolean;
};

const selectButtonTypes = ["By rental listing id", "By property links"];
type SelectButtonType = (typeof selectButtonTypes)[number];

export const UwAssistant = () => {
  const [listingIdTextValue, setListingIdTextValue] = useState("");
  const [urlTextValue1, setUrlTextValue1] = useState("");
  const [urlTextValue2, setUrlTextValue2] = useState("");
  const [urlTextValue3, setUrlTextValue3] = useState("");

  const [loading, setLoading] = useState(false);
  const [listingPdfs, setListingPdfs] = useState<ListingPdf[]>([]);
  const [selectedListingType, setSelectedListingType] =
    useState<ListingType>("airbnb");
  const [selectButtonValue, setSelectButtonValue] = useState<SelectButtonType>(
    "By rental listing id"
  );

  const toast = useRef<Toast>(null);

  const buttonDisabled =
    selectButtonValue === "By rental listing id"
      ? listingIdTextValue === ""
      : urlTextValue1 === "" && urlTextValue2 === "" && urlTextValue3 === "";

  const generatePdf = async () => {
    const listingId = listingIdTextValue;
    if (loading) {
      return;
    }
    setListingIdTextValue("");
    setUrlTextValue1("");
    setUrlTextValue2("");
    setUrlTextValue3("");

    setLoading(true);
    const responseData =
      selectButtonValue === "By rental listing id"
        ? await generatePdfFromListingId(
            listingIdTextValue,
            selectedListingType
          )
        : await generatePdfFromPropertyUrls(
            [urlTextValue1, urlTextValue2, urlTextValue3].filter(
              (url) => url.length > 0
            )
          );
    setLoading(false);
    if (responseData === undefined) {
      console.error("Failed to generate pdf");
      toast.current?.show({
        severity: "warn",
        summary: "PDF Generation Failed",
        detail:
          "Failed to generate the PDF with an unknown error. Please try again.",
        life: 5000,
      });
      return;
    }

    const blob = new Blob([responseData], { type: "application/pdf" });

    // Display the PDF inline
    const url = window.URL.createObjectURL(blob);
    setListingPdfs((currentBlobUrls) => {
      const fromPropertyLinks = selectButtonValue === "By property links";
      const blobListingId = fromPropertyLinks ? uuidv4() : listingId;
      return [
        {
          pdfUrl: url,
          listingId: blobListingId,
          fromPropertyLinks: fromPropertyLinks,
        },
        ...currentBlobUrls,
      ];
    });
  };

  return (
    <div className={styles.topContainer}>
      <Toast ref={toast} />
      <div className={styles.listingsContainer}>
        <p style={{ color: "var(--text-color-secondary)", marginTop: "32px" }}>
          {listingPdfs.length === 0
            ? "No listings yet"
            : "These will disappear on page refresh"}
        </p>

        {listingPdfs.map((airbnbPdf) => (
          <Card key={airbnbPdf.listingId} style={{ width: "100%" }}>
            <a href={airbnbPdf.pdfUrl} target="_blank" rel="noreferrer">
              {airbnbPdf.fromPropertyLinks
                ? "Property link listing"
                : `Listing ${airbnbPdf.listingId}`}
            </a>
          </Card>
        ))}
      </div>
      <div className={styles.cardContainer}>
        <Card className={styles.card}>
          <div className={styles.selectButtonContainer}>
            <SelectButton
              value={selectButtonValue}
              onChange={(e) => setSelectButtonValue(e.value)}
              options={selectButtonTypes}
            />
          </div>
          {selectButtonValue === "By rental listing id" && (
            <ListingIdForm
              textValue={listingIdTextValue}
              setTextValue={setListingIdTextValue}
              selectedListingType={selectedListingType}
              setSelectedListingType={setSelectedListingType}
              generatePdf={generatePdf}
            />
          )}
          {selectButtonValue === "By property links" && (
            <PropertyLinksForm
              urlTextValue1={urlTextValue1}
              urlTextValue2={urlTextValue2}
              urlTextValue3={urlTextValue3}
              setUrlTextValue1={setUrlTextValue1}
              setUrlTextValue2={setUrlTextValue2}
              setUrlTextValue3={setUrlTextValue3}
            />
          )}
          <Button
            className={styles.submitButton}
            label="Generate PDF"
            disabled={buttonDisabled}
            onClick={generatePdf}
            loading={loading}
          />
          {loading && <p>Grab a coffee, this might take a few minutes...</p>}
        </Card>
      </div>
    </div>
  );
};
