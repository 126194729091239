export type NearestAirbnb = {
  listingId: string;
  distanceText: string;
};

export const listingTypes = [
  "airbnb",
  "vrbo",
  "evolve",
  "furnished_finder",
] as const;

export type ListingType = (typeof listingTypes)[number];

export type AuthenticationResponse = "success" | "failure" | "unauthorized";
