import { Splash } from "./Splash";
import { useContext, useEffect, useState } from "react";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Wordmark } from "./components/wordmark/Wordmark";
import styles from "./LoggedOut.module.css";
import { Company } from "./pages/company/Company";
import { Landing } from "./pages/landing/Landing";
import { motion } from "framer-motion";
import { ThemeContext } from "./themes/Theme";
import { Security } from "./pages/security/Security";
import { TermsOfService } from "./pages/security/TermsOfService";
import { PrivacyPolicy } from "./pages/security/PrivacyPolicy";

type LoggedOutProps = {
  incrementAuthCount: () => void;
};

type LoggedOutPage =
  | "company"
  | "sign_in"
  | "landing"
  | "tos"
  | "privacy_policy"
  | "security";

export const LoggedOut: React.FC<LoggedOutProps> = ({ incrementAuthCount }) => {
  const [page, setPage] = useState<LoggedOutPage>("landing");
  const [hasShownAdditionalPage, setHasShownAdditionalPage] = useState(false);
  const { setThemeOverride } = useContext(ThemeContext);

  useEffect(() => {
    if (page !== "landing") {
      setHasShownAdditionalPage(true);
    }

    setThemeOverride("dark");

    return () => {
      setThemeOverride(undefined);
    };
  }, [page, setThemeOverride]);

  return (
    <div className={styles.container}>
      <Toolbar
        className={styles.toolbar}
        start={<Wordmark onClick={() => setPage("landing")} />}
        end={
          <>
            <Button
              text
              onClick={() => setPage("sign_in")}
              className={styles.linkButton}
            >
              SIGN IN
            </Button>
            {/*
            We can comment this back in when we want to show company members
            <Button
              text
              onClick={() => setPage("company")}
              className={styles.linkButton}
            >
              COMPANY
            </Button>
        */}
          </>
        }
      />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className={styles.contentContainer}
        key={page}
        style={page === "security" ? { justifyContent: "flex-start" } : {}}
      >
        {page === "landing" && (
          <Landing withInitialAnimation={!hasShownAdditionalPage} />
        )}
        {page === "sign_in" && (
          <Splash incrementAuthCount={incrementAuthCount} />
        )}
        {page === "company" && <Company />}
        {page === "security" && <Security />}
        {page === "tos" && <TermsOfService />}
        {page === "privacy_policy" && <PrivacyPolicy />}
      </motion.div>
      <div className={styles.footer}>
        <Button
          text
          className={styles.linkButton}
          style={{ marginRight: "32px" }}
          onClick={() => {
            window.open("mailto:info@pantheonai.co?subject=Hello!", "_blank");
          }}
        >
          INFO@PANTHEONAI.CO
        </Button>
        <Button
          text
          className={styles.linkButton}
          style={{ fontSize: "10px" }}
          onClick={() => setPage("tos")}
        >
          TERMS OF SERVICE
        </Button>
        <Button
          text
          className={styles.linkButton}
          style={{ fontSize: "10px" }}
          onClick={() => setPage("privacy_policy")}
        >
          PRIVACY POLICY
        </Button>
        <Button
          text
          className={styles.linkButton}
          style={{ fontSize: "10px" }}
          onClick={() => setPage("security")}
        >
          SECURITY
        </Button>
      </div>
    </div>
  );
};
