import { buildChannel, isRunningLocally } from "./util";
import amplitude from "amplitude-js";

const AMPLITUDE_API_KEY = isRunningLocally()
  ? "70c88231ad2fe45d2498a07e78adc5d2"
  : "1a5881921e6fb1d6b71eaccf9745528c";

const instance = amplitude.getInstance();
instance.init(AMPLITUDE_API_KEY);

export const logEvent = (
  eventName: string,
  eventProperties?: { [field: string]: any }
) => {
  const allEventProperties = eventProperties ?? {};
  allEventProperties["build_channel"] = buildChannel();

  if (isRunningLocally()) {
    console.log(
      `Log ${eventName} to Amplitude with event properties`,
      eventProperties
    );
  }

  instance.logEvent(eventName, allEventProperties);
};
