import { UwAssistant } from "./pages/uw-assistant/UwAssistant";
import { Menubar } from "primereact/menubar";
import styles from "./LoggedIn.module.css";
import { MenuItem } from "primereact/menuitem";
import { useState } from "react";
import { AirbnbVerification } from "./AirbnbVerification";
import { Wordmark } from "./components/wordmark/Wordmark";

enum PageType {
  UwAssistant = "uw_assistant",
  AirbnbVerification = "airbnb_verification",
}

export const LoggedIn = () => {
  const [currentPage, setCurrentPage] = useState(PageType.UwAssistant);

  const menuItems: MenuItem[] = [
    {
      id: PageType.UwAssistant,
      label: "STR Underwriter Assistant",
      icon: "pi pi-home",
      command: () => {
        setCurrentPage(PageType.UwAssistant);
      },
    },
    {
      id: PageType.AirbnbVerification,
      label: "Airbnb Verification",
      icon: "pi pi-verified",
      command: () => {
        setCurrentPage(PageType.AirbnbVerification);
      },
    },
  ];

  return (
    <div className={styles.loggedInContainer}>
      <Menubar
        start={<Wordmark className={styles.wordMarkPadding} height="32px" />}
        model={menuItems}
        style={{ backgroundColor: "var(--surface-0)" }}
      />
      {currentPage === PageType.UwAssistant && <UwAssistant />}
      {currentPage === PageType.AirbnbVerification && <AirbnbVerification />}
    </div>
  );
};
