import { InputText } from "primereact/inputtext";
import { useRef, useState } from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { authenticate } from "./api/routes";
import styles from "./Splash.module.css";
import { Toast } from "primereact/toast";

type SplashProps = {
  incrementAuthCount: () => void;
};

export const Splash: React.FC<SplashProps> = ({ incrementAuthCount }) => {
  const [textValue, setTextValue] = useState("");
  const [loading, setLoading] = useState(false);

  const toast = useRef<Toast>(null);

  const makeAuthCall = () => {
    setLoading(true);
    authenticate(textValue).then((result) => {
      setLoading(false);

      if (result === "success") {
        localStorage.setItem("authedCompany", "proper");
        incrementAuthCount();
      } else if (result === "unauthorized") {
        toast.current?.show({
          severity: "warn",
          summary: "Login Failed",
          detail: "Please enter a valid password.",
          life: 5000,
        });
      }
    });
  };

  return (
    <div className={styles.splashContainer}>
      <Toast ref={toast} />
      <Card title="Welcome!">
        <p className="m-0">Please enter your company password to begin.</p>
        <InputText
          value={textValue}
          onChange={(e) => setTextValue(e.target.value)}
          className={styles.inputText}
          onKeyDown={(event) => {
            if (event.key === "Enter" && textValue !== "") {
              makeAuthCall();
            }
          }}
        />
        <Button
          className={styles.submitButton}
          label="Submit"
          disabled={textValue === ""}
          onClick={makeAuthCall}
          loading={loading}
        />
      </Card>
    </div>
  );
};
